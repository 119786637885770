import * as fromActions from './items.action';
import { Item, ItemBrand, ItemDetailType, Supplier, SupplierItem, ItemFilter, ItemImage, BrandItem, valueLabel } from './../../models';
import { Country } from 'app/_models';
import { HighLevelTypes } from 'app/_models/high-level-type';
import { store } from '@angular/core/src/render3';

export class ItemsState {
  loaded: boolean;
  loading: boolean;
  items: Item[];
  itemsEntities: { [key in ItemEntitiesValues]: Item[] };
  filter: ItemFilter;
  currentItem: Item;
  currentMixedCase: Item;
  currentMixedItem: Item;
  currentMixedCaseItems: Item[];

  itemBrand: ItemBrand;
  brands: ItemBrand[];
  itemImages: ItemImage[];
  countries: Country[];
  itemDetailTypes: ItemDetailType[];
  excelTemplateUrl: string;
  suppliers: Supplier[];
  supplierItem: SupplierItem;
  brandItem: BrandItem;
  supplierItemConditionSuggest: string[];
  highLevelTypes: HighLevelTypes[];
  error: any;
  labels: valueLabel[];
  showDeleteError: string;
  showDeleteConfirmation: boolean;
  createMixedCase: boolean;
}

const initialState: ItemsState = {
  loaded: false,
  loading: false,
  items: [],
  currentMixedCaseItems: [],
  itemsEntities: {
    all: [],
    is_new: [],
    archived: [],
    in_review: [],
    incomplete: [],
    published: [],
    scheduled: []
  },
  filter: null,
  currentItem: null,
  currentMixedCase: null,
  currentMixedItem: null,
  itemBrand: null,
  brands: [],
  labels: [],
  itemImages: [],
  countries: [],
  itemDetailTypes: [],
  excelTemplateUrl: null,
  suppliers: [],
  brandItem: null,
  supplierItem: null,
  highLevelTypes: [],
  supplierItemConditionSuggest: [],
  error: null,
  showDeleteError: 'false',
  showDeleteConfirmation: false,
  createMixedCase: false
};

export function itemsReducer(
  state: ItemsState = initialState,
  action: fromActions.ItemsAction
): ItemsState {
  switch (action.type) {
    case fromActions.LOAD_ITEMS:
    case fromActions.SEARCH_ITEMS:
    case fromActions.LOAD_ITEM:
    case fromActions.DUPLICATE_ITEM:
    case fromActions.DELETE_SUPPLIER:
    case fromActions.LOAD_SUGGESTED_IMAGES:
    case fromActions.CREATE_ITEM:
    case fromActions.UPDATE_ITEM:
    case fromActions.DELETE_ITEM:
    case fromActions.CREATE_ITEM_DETAIL:
    case fromActions.UPDATE_ITEM_DETAIL:
    case fromActions.DELETE_ITEM_DETAIL:
    case fromActions.UPLOAD_ITEM_IMAGE:
    case fromActions.DELETE_ITEM_IMAGE:
    case fromActions.LOAD_BRANDS:
    case fromActions.LOAD_COUNTRIES:
    case fromActions.LOAD_ITEM_DETAIL_TYPES:
    case fromActions.LOAD_SUPPLIERS:
    case fromActions.LOAD_SUPPLIER_ITEM:
    case fromActions.CREATE_SUPPLIER_ITEM:
    case fromActions.LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST:
    case fromActions.CREATE_BRAND:
    case fromActions.UPDATE_BRAND:
    case fromActions.GET_EXCEL_TEMPLATE:
    case fromActions.IMPORT_ITEMS:
    case fromActions.EXPORT_ITEMS:
    case fromActions.CREATE_ITEM_REVIEW:
    case fromActions.UPLOAD_DB_ITEM_IMAGE:
      {
        // console.log('DELETE_ITEM', action['payload']);

        return {
          ...state,
          loading: true,
          loaded: false,
          showDeleteConfirmation: false
        };
      }

    case fromActions.LOAD_ITEMS_SUCCESS:
    case fromActions.SEARCH_ITEMS_SUCCESS: {
      // console.log('LOAD_ITEMS_SUCCESS', action['payload'].items);
      const date = new Date();

      const newItemsEntities: { [key in ItemEntitiesValues]: Item[] } = {
        all: [],
        is_new: [],
        archived: [],
        in_review: [],
        incomplete: [],
        published: [],
        scheduled: []
      };

      action['payload'].items.forEach((item: Item) => {
        for (let keyName in ItemEntitiesValues) {
          const key = ItemEntitiesValues[keyName];
          if (item[key] == true && key != 'published' && key != 'scheduled') {
            newItemsEntities[key].push(item);
            return;
          }

          const schedule_date = new Date(item.schedule_date);
          if (item[key] == true && key == 'published' && !item.schedule_date) {
            newItemsEntities[key].push(item);
            return;
          }

          if (item[key] == true && key == 'published' && item.schedule_date && schedule_date < date) {
            newItemsEntities[key].push(item);
            return;
          }

          if (item.published == true && key == 'scheduled' && item.schedule_date && schedule_date > date) {
            newItemsEntities[key].push(item);
            return;
          }
        }
      });

      let resultItemsEntities: { [key in ItemEntitiesValues]: Item[] } = {
        all: [],
        is_new: [],
        archived: [],
        in_review: [],
        incomplete: [],
        published: [],
        scheduled: []
      };

      // if (!action['payload'].filter) {
      //   resultItemsEntities = newItemsEntities;
      // } else {
      resultItemsEntities = {
        all: newItemsEntities.is_new.length ? newItemsEntities.all : state.itemsEntities.all,
        is_new: newItemsEntities.is_new.length ? newItemsEntities.is_new : state.itemsEntities.is_new,
        in_review: newItemsEntities.in_review.length ? newItemsEntities.in_review : state.itemsEntities.in_review,
        incomplete: newItemsEntities.incomplete.length ? newItemsEntities.incomplete : state.itemsEntities.incomplete,
        archived: newItemsEntities.archived.length ? newItemsEntities.archived : state.itemsEntities.archived,
        published: newItemsEntities.published.length ? newItemsEntities.published : state.itemsEntities.published,
        scheduled: newItemsEntities.scheduled.length ? newItemsEntities.scheduled : state.itemsEntities.scheduled,
      };
      // }

      return {
        ...state,
        items: action['payload'].items,
        itemsEntities: resultItemsEntities,
        error: null,
        loading: false,
        loaded: true
      };
    }

    case fromActions.LOAD_SUGGESTED_IMAGES_SUCCESS: {
      return {
        ...state,
        itemImages: action['payload'].itemImages,
        error: null,
        loading: false,
        loaded: true
      };
    }

    case fromActions.CLEAR_SHOW_DELETE_ERROR: {
      return {
        ...state,
        showDeleteError: 'false',
        showDeleteConfirmation: false
      };
    }


    case fromActions.LOAD_HIGH_LEVEL_TYPES_SUCCESS: {
      return {
        ...state,
        highLevelTypes: action['payload'].highLevelTypes,
      };
    }

    case fromActions.LOAD_HIGH_LEVEL_TYPES_FAIL: {
      return {
        ...state,
        highLevelTypes: [],
      };
    }

    case fromActions.LOAD_ITEMS_FAIL:
    case fromActions.SEARCH_ITEMS_FAIL:
    case fromActions.LOAD_ITEM_FAIL:
    case fromActions.DUPLICATE_ITEM_FAIL:
    case fromActions.DELETE_SUPPLIER_FAIL:
    case fromActions.LOAD_SUGGESTED_IMAGES_FAIL:
    case fromActions.CREATE_ITEM_FAIL:
    case fromActions.UPDATE_ITEM_FAIL:
    case fromActions.CREATE_ITEM_DETAIL_FAIL:
    case fromActions.DELETE_ITEM_IMAGE_FAIL:
    case fromActions.UPLOAD_ITEM_IMAGE_FAIL:
    case fromActions.UPDATE_ITEM_DETAIL_FAIL:
    case fromActions.DELETE_ITEM_DETAIL_FAIL:
    case fromActions.LOAD_BRANDS_FAIL:
    case fromActions.LOAD_COUNTRIES_FAIL:
    case fromActions.LOAD_ITEM_DETAIL_TYPES_FAIL:
    case fromActions.LOAD_SUPPLIERS_FAIL:
    case fromActions.LOAD_SUPPLIER_ITEM_FAIL:
    case fromActions.CREATE_SUPPLIER_ITEM_FAIL:
    case fromActions.LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST_FAIL:
    case fromActions.CREATE_BRAND_FAIL:
    case fromActions.UPDATE_BRAND_FAIL:
    case fromActions.GET_EXCEL_TEMPLATE_FAIL:
    case fromActions.IMPORT_ITEMS_FAIL:
    case fromActions.EXPORT_ITEMS_FAIL:
    case fromActions.CREATE_ITEM_REVIEW_FAIL:
    case fromActions.UPLOAD_DB_ITEM_IMAGE_FAIL:
      {
        // console.log('FAIL', action['payload'].error);
        return {

          ...state,
          items: [],
          error: action['payload'].error,
          loading: false,
          loaded: false
        };
      }

    case fromActions.DELETE_ITEM_FAIL:
      {
        // console.log('FAIL', action['payload'].error.graphQLErrors[0].error.message);
        return {
          ...state,
          showDeleteError: action['payload'].error.graphQLErrors[0].error.message,
          error: action['payload'].error.graphQLErrors[0].error.message,
          loading: false,
          loaded: false
        };
      }

    case fromActions.CLEAR_ITEM:
      // console.log('CLEAR_ITEM');
      {
        return {
          ...state,
          currentMixedCase: null,
          currentMixedItem: null,
          currentItem: null,
          error: null,
          loading: false,
          loaded: false
        };
      }

    case fromActions.CLEAR_MIXED_ITEM:
      // console.log('CLEAR_ITEM');
      {
        return {
          ...state,
          currentMixedItem: null,
          error: null,
          loading: false,
          loaded: false
        };
      }

    case fromActions.DELETE_ITEM_MIXED_IMAGE_SUCCESS:
      {
        const itemImages = state.currentMixedItem.itemMixedCaseImage.filter(it => it._id !== action['payload'].itemMixedCaseItem_id);
        return {
          ...state,
          currentMixedItem: {
            ...state.currentMixedItem,
            itemImages
          },
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.DELETE_ITEM_IMAGE_SUCCESS:
      {
        const itemImages = state.currentItem.itemImages.filter(it => it._id !== action['payload']._id);
        return {
          ...state,
          currentItem: {
            ...state.currentItem,
            itemImages
          },
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.UPLOAD_ITEM_MIXED_IMAGE_SUCCESS:
      console.log('UPLOAD_ITEM_MIXED_IMAGE_SUCCESS')
      {
        const itemImages = [...state.currentMixedCase.itemImages, action['payload']];
        return {
          ...state,
          currentMixedCase: {
            ...state.currentMixedCase,
            itemImages
          },
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.UPLOAD_ITEM_IMAGE_SUCCESS:

      {
        const itemImages = [...state.currentItem.itemImages, action['payload']];
        return {
          ...state,
          currentItem: {
            ...state.currentItem,
            itemImages
          },
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.UPDATE_ITEM_INLINE_SUCCESS:
      {
        const updItem = action['payload'].item;
        // console.log('updatedItem', updItem);
        const updatedSupplierItem = action['payload'].supplierItemInput;
        // console.log('updatedSupplierItem', updatedSupplierItem);

        let updatedCurrentItem: Item = null;

        const updatedItems: Item[] = state.items.slice();

        updatedItems.forEach((item, itemIndex) => {
          if (!item.suppliers.length) {
            return;
          }
          const supplierIndex: number = item.suppliers.findIndex(i => i._id == updatedSupplierItem._id);
          if (supplierIndex >= 0) {
            // console.log('supplierIndex', supplierIndex);
            const updatedSuppliers: SupplierItem[] = item.suppliers.slice();
            // console.log('updatedSuppliers[supplierIndex]', updatedSuppliers[supplierIndex]);
            const updSupplier: SupplierItem = {
              _id: updatedSuppliers[supplierIndex]._id,
              sku: updatedSuppliers[supplierIndex].sku,
              // currency: updatedSuppliers[supplierIndex].currency,
              item_id: updatedSuppliers[supplierIndex].item_id,
              supplier_id: updatedSuppliers[supplierIndex].supplier_id,
              supplier: updatedSuppliers[supplierIndex].supplier,
              price: updatedSupplierItem.price,
              stockQty: updatedSupplierItem.stockQty,
              currency: updatedSupplierItem.currency,
              isDiscounted: updatedSupplierItem.isDiscounted
            }

            updatedSuppliers[supplierIndex] = updSupplier;
            // updSupplier[supplierIndex].stockQty = updatedSupplierItem.stockQty;
            // updSupplier[supplierIndex].price = updatedSupplierItem.price;
            const updatedItem = { ...updItem, suppliers: updatedSuppliers };
            updatedItems[itemIndex] = updatedItem;
            // console.log('updatedItems', updatedItems);
            // console.log('updSupplier', updSupplier);
          }
        })

        // console.log('updatedSupplierItem', updatedSupplierItem);

        return {
          ...state,
          supplierItem: updatedSupplierItem,
          currentItem: updItem,
          items: updatedItems,
          error: null,
          loading: false,
          loaded: true
        };
      }


    case fromActions.CREATE_ITEM_SUCCESS:
      // console.log('xxx cccCREATE_ITEM_SUCCESS', action['payload'].item);
      {
        return {
          ...state,
          currentItem: action['payload'].item,
          error: null,
          loading: false,
          loaded: true,
          createMixedCase: action['payload'].item.mixed_case
        };
      }

    case fromActions.CREATE_MIXED_ITEM_SUCCESS:
      // console.log('xxx CREATE_MIXED_ITEM_SUCCESS', action['payload'].item);
      {
        return {
          ...state,
          currentMixedItem: action['payload'].item,
          error: null,
          loading: false,
          loaded: true
        };
      }
    case fromActions.UPDATE_ITEM_SUCCESS:
    // case fromActions.UPDATE_ITEM_INLINE_SUCCESS:
    case fromActions.LOAD_ITEM_SUCCESS:
    case fromActions.DUPLICATE_ITEM_SUCCESS:
    case fromActions.IMPORT_ITEMS_SUCCESS:
      // console.log('LOAD_ITEM_SUCCESS');

      {
        return {
          ...state,
          currentItem: action['payload'].item,
          error: null,
          loading: false,
          loaded: true,
          createMixedCase: false
        };
      }

    case fromActions.LOAD_MIXED_CASE_SUCCESS:
      // console.log('LOAD_MIXED_CASE_SUCCESS', action['payload']);
      {
        return {
          ...state,
          currentMixedCase: action['payload'].item,
          currentMixedCaseItems: action['payload'].item.itemMixedCaseItems,
          error: null,
          loading: false,
          loaded: true,
          createMixedCase: false
        };
      }

    case fromActions.UPDATE_MIXED_ITEM_SUCCESS:

      const updatedMixedCaseItems: Item[] = state.currentMixedCaseItems.slice();

      // console.log('UPDATE_MIXED_ITEM_SUCCESS', updatedMixedCaseItems);

      updatedMixedCaseItems.forEach((item, itemIndex) => {
        // console.log('UPDATE_MIXED_ITEM_SUCCESS', item);
        // console.log('UPDATE_MIXED_ITEM_SUCCESS', itemIndex);
        // if (!item.suppliers.length) {
        //   return;
        // }
        // const supplierIndex: number = item.suppliers.findIndex(i => i._id == updatedSupplierItem._id);
        // if (supplierIndex >= 0) {
        //   const updatedSuppliers: SupplierItem[] = item.suppliers.slice();
        //   const updSupplier: SupplierItem = {
        //     _id: updatedSuppliers[supplierIndex]._id,
        //     sku: updatedSuppliers[supplierIndex].sku,
        //     item_id: updatedSuppliers[supplierIndex].item_id,
        //     supplier_id: updatedSuppliers[supplierIndex].supplier_id,
        //     supplier: updatedSuppliers[supplierIndex].supplier,
        //     price: updatedSupplierItem.price,
        //     stockQty: updatedSupplierItem.stockQty,
        //     currency: updatedSupplierItem.currency,
        //     isDiscounted: updatedSupplierItem.isDiscounted
        //   }
        //   updatedSuppliers[supplierIndex] = updSupplier;
        //   const updatedItem = { ...updItem, suppliers: updatedSuppliers };
        //   updatedItems[itemIndex] = updatedItem;

        // }
      })

      {
        return {
          ...state,
          currentMixedItem: action['payload'].item,
          error: null,
          loading: false,
          loaded: true,
          createMixedCase: false
        };
      }



    case fromActions.LOAD_MIXED_ITEM_SUCCESS:
      // console.log('LOAD_MIXED_ITEM_SUCCESS');
      {
        return {
          ...state,
          currentMixedItem: action['payload'].item,
          error: null,
          loading: false,
          loaded: true,
          createMixedCase: false
        };
      }

    case fromActions.CREATE_ITEM_DETAIL_SUCCESS:
      // let itemdetail: any = state && state.currentItem && state.currentItem.itemDetails ? state.currentItem.itemDetails : '';
      // console.log(state)
      {
        return {
          ...state,
          currentItem: {
            ...state.currentItem,
            itemDetails: [...state.currentItem.itemDetails, action['payload'].itemDetail]
          },
          error: null,
          loading: false,
          loaded: true,
          createMixedCase: false
        };
      }

    case fromActions.UPDATE_ITEM_DETAIL_SUCCESS:
      // console.log(state)
      {
        const updatedItemDetails = state.currentItem.itemDetails.slice();
        const foundIndex = updatedItemDetails.findIndex(i => i._id == action['payload'].itemDetail._id);
        updatedItemDetails[foundIndex] = action['payload'].itemDetail;
        return {
          ...state,
          currentItem: {
            ...state.currentItem,
            itemDetails: updatedItemDetails
          },
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.UPDATE_MIXED_ITEM_DETAIL_SUCCESS:
      // console.log(state)
      {
        const updatedItemDetails = state.currentMixedItem.itemMixedCaseDetail.slice();
        const foundIndex = updatedItemDetails.findIndex(i => i._id == action['payload'].itemDetail._id);
        updatedItemDetails[foundIndex] = action['payload'].itemDetail;
        return {
          ...state,
          currentMixedItem: {
            ...state.currentMixedItem,
            itemMixedCaseDetail: updatedItemDetails
          },
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.DELETE_ITEM_DETAIL_SUCCESS:
      {
        const updatedItemDetails = state.currentItem.itemDetails.slice().filter(item => item._id != action['payload'].itemDetail._id);
        return {
          ...state,
          currentItem: {
            ...state.currentItem,
            itemDetails: updatedItemDetails
          },
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.LOAD_BRANDS_SUCCESS:
      let brands = action['payload'].brands
      if (brands && brands[0] && brands[0].name !== "Various") {
        var obj = null;
        for (var i = 0; i < brands.length; i++) {
          if (brands[i].name == "Various") {
            obj = brands[i];
            break;
          }
        }
        brands.unshift(obj);
      }
      {
        return {
          ...state,
          brands: brands,
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.LOAD_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action['payload'].countries,
        error: null,
        loading: false,
        loaded: true
      };
    }
    case fromActions.LOAD_ITEM_DETAIL_TYPES_SUCCESS: {
      return {
        ...state,
        itemDetailTypes: action['payload'].itemDetailTypes,
        error: null,
        loading: false,
        loaded: true
      };
    }

    case fromActions.DELETE_ITEM_SUCCESS: {
      const updatedItems = state.items.slice().filter(item => item._id != action['payload'].item._id);
      // console.log('DELETE_ITEM_SUCCESS', action['payload'])
      return {
        ...state,
        showDeleteConfirmation: true,
        items: updatedItems,
        error: null,
        loading: false,
        loaded: true
      };
    }

    case fromActions.DELETE_MIXED_ITEM_SUCCESS: {
      const updatedItems = state.currentMixedCaseItems.slice().filter(item => item._id != action['payload'].item._id);
      return {
        ...state,
        showDeleteConfirmation: true,
        currentMixedCaseItems: updatedItems,
        error: null,
        loading: false,
        loaded: true
      };
    }

    case fromActions.LOAD_SUPPLIERS_SUCCESS:
      {
        return {
          ...state,
          suppliers: action['payload'].suppliers,
          error: null,
          loading: false,
          loaded: true
        };
      }


    case fromActions.CREATE_SUPPLIER_ITEM_SUCCESS:
      console.log(state.currentMixedCase)
      if (state.currentMixedCase) {
        {
          return {
            ...state,
            supplierItem: action['payload'].supplierItem,
            currentMixedCase: {
              ...state.currentMixedCase,
              suppliers: [...state.currentMixedCase.suppliers, action['payload'].supplierItem]
            },
            error: null,
            loading: false,
            loaded: true
          };
        }
      } else {
        {
          return {
            ...state,
            supplierItem: action['payload'].supplierItem,
            currentItem: {
              ...state.currentItem,
              suppliers: [...state.currentItem.suppliers, action['payload'].supplierItem]
            },
            error: null,
            loading: false,
            loaded: true
          };
        }
      }

    case fromActions.DELETE_SUPPLIER_SUCCESS:
      if (state.currentMixedCase) {
        {
          let suppliers = state.currentMixedCase.suppliers.slice();
          let deleteSup = action['payload'].supplierItem.data.deleteSupplierItem

          // const foundIndex = suppliers.findIndex(i => i._id == deleteSup);
          // suppliers[foundIndex] = action['payload'].supplierItem;

          const index = suppliers.findIndex(x => x._id === deleteSup._id);
          suppliers.splice(index, 1);
          // console.log('suppliers', suppliers);

          if (!suppliers.length) {
            suppliers = [];
          }

          return {
            ...state,
            supplierItem: action['payload'].supplierItem,
            currentMixedCase: {
              ...state.currentMixedCase,
              suppliers
            },
            error: null,
            loading: false,
            loaded: true
          };
        }
      } else {
        {
          let suppliers = state.currentItem.suppliers.slice();
          let deleteSup = action['payload'].supplierItem.data.deleteSupplierItem

          // const foundIndex = suppliers.findIndex(i => i._id == deleteSup);
          // suppliers[foundIndex] = action['payload'].supplierItem;

          const index = suppliers.findIndex(x => x._id === deleteSup._id);
          suppliers.splice(index, 1);
          // console.log('suppliers', suppliers);

          if (!suppliers.length) {
            suppliers = [];
          }

          return {
            ...state,
            supplierItem: action['payload'].supplierItem,
            currentItem: {
              ...state.currentItem,
              suppliers
            },
            error: null,
            loading: false,
            loaded: true
          };
        }
      }

    case fromActions.LOAD_MIXED_CASE_SUPPLIER_ITEM_SUCCESS:
      {
        const suppliers = state.currentMixedCase.suppliers.slice();
        const foundIndex = suppliers.findIndex(i => i._id == action['payload'].supplierItem._id);
        suppliers[foundIndex] = action['payload'].supplierItem;

        return {
          ...state,
          supplierItem: action['payload'].supplierItem,
          currentMixedCase: {
            ...state.currentMixedCase,
            suppliers
          },
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.LOAD_SUPPLIER_ITEM_SUCCESS:
      {
        const suppliers = state.currentItem.suppliers.slice();
        const foundIndex = suppliers.findIndex(i => i._id == action['payload'].supplierItem._id);
        suppliers[foundIndex] = action['payload'].supplierItem;

        return {
          ...state,
          supplierItem: action['payload'].supplierItem,
          currentItem: {
            ...state.currentItem,
            suppliers
          },
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.UPDATE_SUPPLIER_ITEM_SUCCESS:
      {
        const updatedSupplierItem = action['payload'].supplierItem;
        // console.log('updatedSupplierItem', updatedSupplierItem);
        let updatedCurrentItem: Item = null;
        if (state.currentItem && state.currentItem.suppliers) {
          // console.log('state.currentItem');
          const suppliers = state.currentItem.suppliers.slice();
          const foundIndex = suppliers.findIndex(i => i._id == updatedSupplierItem._id);
          const updSupplier: SupplierItem = {
            _id: updatedSupplierItem._id,
            item_id: suppliers[foundIndex].item_id,
            supplier_id: suppliers[foundIndex].supplier_id,
            supplier: suppliers[foundIndex].supplier,
            price: updatedSupplierItem.price,
            stockQty: updatedSupplierItem.stockQty,
            currency: updatedSupplierItem.currency,
            soldInMultiplesOf: updatedSupplierItem.soldInMultiplesOf,
            sku: updatedSupplierItem.sku,
            discountedPrice: updatedSupplierItem.discountedPrice,
            discountedPriceValidFrom: updatedSupplierItem.discountedPriceValidFrom,
            discountedPriceValidTo: updatedSupplierItem.discountedPriceValidTo,
            limitQty: updatedSupplierItem.limitQty,
            limitStock: updatedSupplierItem.limitStock
          }
          suppliers[foundIndex] = updSupplier
          updatedCurrentItem = {
            ...state.currentItem,
            suppliers
          }
        }

        const updatedItems: Item[] = state.items.slice();
        updatedItems.forEach((item, itemIndex) => {
          if (!item.suppliers.length) {
            return;
          }
          const supplierIndex: number = item.suppliers.findIndex(i => i._id == updatedSupplierItem._id);
          if (supplierIndex >= 0) {
            // console.log('supplierIndex');
            const updatedSuppliers: SupplierItem[] = item.suppliers.slice();
            // console.log('updatedSuppliers[supplierIndex]', updatedSuppliers[supplierIndex]);
            const updSupplier: SupplierItem = {
              _id: updatedSuppliers[supplierIndex]._id,
              sku: updatedSuppliers[supplierIndex].sku,
              // currency: updatedSuppliers[supplierIndex].currency,
              item_id: updatedSuppliers[supplierIndex].item_id,
              supplier_id: updatedSuppliers[supplierIndex].supplier_id,
              supplier: updatedSuppliers[supplierIndex].supplier,
              price: updatedSupplierItem.price,
              stockQty: updatedSupplierItem.stockQty,
              currency: updatedSupplierItem.currency,
              limitQty: updatedSupplierItem.limitQty,
              limitStock: updatedSupplierItem.limitStock
            }
            updatedSuppliers[supplierIndex] = updSupplier;
            // updSupplier[supplierIndex].stockQty = updatedSupplierItem.stockQty;
            // updSupplier[supplierIndex].price = updatedSupplierItem.price;
            const updatedItem = { ...item, suppliers: updatedSuppliers };
            updatedItems[itemIndex] = updatedItem;
            // console.log('updatedItems', updatedItems);
          }
        })

        return {
          ...state,
          supplierItem: updatedSupplierItem,
          currentItem: updatedCurrentItem,
          items: updatedItems,
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST_SUCCESS:
      {
        return {
          ...state,
          supplierItemConditionSuggest: [...action['payload'].conditions],
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.CREATE_BRAND_SUCCESS:
    case fromActions.UPDATE_BRAND_SUCCESS:
      {
        return {
          ...state,
          itemBrand: action['payload'].brand,
          currentItem: {
            ...state.currentItem,
            brand_id: action['payload'].brand._id,
            brandDescription: action['payload'].brand.name,
          },
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.GET_EXCEL_TEMPLATE_SUCCESS:
      {
        return {
          ...state,
          excelTemplateUrl: action['payload'].excelTemplateUrl,
          error: null,
          loading: false,
          loaded: true
        };
      }

    case fromActions.EXPORT_ITEMS_SUCCESS:
    case fromActions.CREATE_ITEM_REVIEW_SUCCESS:
    case fromActions.UPLOAD_DB_ITEM_IMAGE_SUCCESS:
      {
        return {
          ...state,
          error: null,
          loading: false,
          loaded: true
        };
      }
    case fromActions.LOAD_LABELS_SUCCESS:
      {
        return {
          ...state,
          labels: action['payload'].labels,
          error: null,
          loading: false,
          loaded: true
        };
      }
    default: {
      return state;
    }
  }
}

export const getLoading: any = (state: ItemsState): boolean => state.loading;
export const getLoaded: any = (state: ItemsState): boolean => state.loaded;
export const getItems: any = (state: ItemsState): Item[] => state.items;
export const getItemImages: any = (state: ItemsState): ItemImage[] => state.itemImages;
export const getItemsEntities: any = (state: ItemsState): { [key in ItemEntitiesValues]: Item[] } => state.itemsEntities;
export const getCurrentItem: any = (state: ItemsState): Item => state.currentItem;
export const getCurrentMixedCase: any = (state: ItemsState): Item => state.currentMixedCase;

export const getMixedCaseItems: any = (state: ItemsState): Item[] => state.currentMixedCaseItems;

export const getCurrentMixedItem: any = (state: ItemsState): Item => state.currentMixedItem;

export const getBrands: any = (state: ItemsState): ItemBrand[] => state.brands;
export const getLabels: any = (state: ItemsState): valueLabel[] => state.labels;

export const getBrandItem: any = (state: ItemsState): BrandItem => state.brandItem;
export const getCountries: any = (state: ItemsState): Country[] => state.countries;
export const getItemDetails: any = (state: ItemsState): ItemDetailType[] => state.itemDetailTypes;


export const getSuppliers: any = (state: ItemsState): Supplier[] => state.suppliers;
export const getSupplierItem: any = (state: ItemsState): SupplierItem => state.supplierItem;
export const getHighLevelTypes: any = (state: ItemsState): HighLevelTypes[] => state.highLevelTypes;
export const getSupplierItemConditionSuggest: any = (state: ItemsState): string[] => state.supplierItemConditionSuggest;
export const getItemDetailTypes: any = (state: ItemsState): ItemDetailType[] => state.itemDetailTypes;
export const getExcelTemplateUrl: any = (state: ItemsState): string => state.excelTemplateUrl;
export const getError: any = (state: ItemsState): any => state.error;


export const getShowDeleteError: any = (state: ItemsState): string => state.showDeleteError;

export const getShowDeleteConfirmation: any = (state: ItemsState): boolean => state.showDeleteConfirmation;

export const getMixedCase: any = (state: ItemsState): boolean => state.createMixedCase;


export enum ItemEntitiesValues {
  ALL = 'all',
  NEW = 'is_new',
  IN_REVIW = 'in_review',
  INCOMPLETE = 'incomplete',
  ARCHIVED = 'archived',
  PUBLISHED = 'published',
  SCHEDULED = 'scheduled',
}
