import { Action } from '@ngrx/store';
import { valueLabel, Item, ItemInput, ItemBrand, ItemDetail, ItemDetailInput, ItemDetailType, ItemImage, Supplier, SupplierItemInput, SupplierItem, BrandInput, ItemFilter, ItemInline, ItemMixedCaseItemInput } from '../../models';
import { Country } from 'app/_models';
import { HighLevelTypes } from 'app/_models/high-level-type';
import { ItemReview } from '../../models/item-review';

export const LOAD_ITEMS: string = '[Items] Load Items';
export const LOAD_ITEMS_SUCCESS: string = '[Items] Load Items Success';
export const LOAD_ITEMS_FAIL: string = '[Items] Load Items Fail';

export class LoadItems implements Action {
  readonly type: string = LOAD_ITEMS;
  constructor(public payload: { filter: ItemFilter }) { }
}

export class LoadItemsSuccess implements Action {
  readonly type: string = LOAD_ITEMS_SUCCESS;
  constructor(public payload: { items: Item[], filter?: ItemFilter }) { }
}

export const LOAD_LABELS: string = '[Items] Load Labels';
export const LOAD_LABELS_SUCCESS: string = '[Items] Load Labels Success';
export const LOAD_LABELS_FAIL: string = '[Items] Load Labels Fail';

export class LoadLabels implements Action {
  readonly type: string = LOAD_LABELS;
  constructor() { console.log('xxx LoadLabels') }
}

export class LoadLabelsSuccess implements Action {
  readonly type: string = LOAD_LABELS_SUCCESS;
  constructor(public payload: { labels: valueLabel[] }) { }
}

export class LoadLabelsFail implements Action {
  readonly type: string = LOAD_LABELS_FAIL;
  constructor(public payload: { error: any }) { }
}
///

export class LoadItemsFail implements Action {
  readonly type: string = LOAD_ITEMS_FAIL;
  constructor(public payload: { error: any }) { }
}

export const LOAD_HIGH_LEVEL_TYPES: string = '[HighLevelTypes] Load HighLevelTypes';
export const LOAD_HIGH_LEVEL_TYPES_SUCCESS: string = '[HighLevelTypes] Load HighLevelTypes Success';
export const LOAD_HIGH_LEVEL_TYPES_FAIL: string = '[HighLevelTypes] Load HighLevelTypes Fail';

export class LoadHighLevelTypes implements Action {
  readonly type: string = LOAD_HIGH_LEVEL_TYPES;
  constructor() { }
}

export class LoadHighLevelTypesSuccess implements Action {
  readonly type: string = LOAD_HIGH_LEVEL_TYPES_SUCCESS;
  constructor(public payload: { highLevelTypes: HighLevelTypes[] }) { }
}

export class LoadHighLevelTypesFail implements Action {
  readonly type: string = LOAD_HIGH_LEVEL_TYPES_FAIL;
  constructor(public payload: { error: any }) { }
}

export const SEARCH_ITEMS: string = '[Items] Search Items';
export const SEARCH_ITEMS_SUCCESS: string = '[Items] Search Items Success';
export const SEARCH_ITEMS_FAIL: string = '[Items] Search Items Fail';

export class SearchItems implements Action {
  readonly type: string = SEARCH_ITEMS;
  constructor(public payload: { query: string, limit: number, filters: any }) { }
}

export class SearchItemsSuccess implements Action {
  readonly type: string = SEARCH_ITEMS_SUCCESS;
  constructor(public payload: { items: Item[] }) { }
}

export class SearchItemsFail implements Action {
  readonly type: string = SEARCH_ITEMS_FAIL;
  constructor(public payload: { error: any }) { }
}

///
export const LOAD_MIXED_CASE_ITEMS: string = '[Items] Load Mixed Case Items';
export const LOAD_MIXED_CASE_ITEMS_SUCCESS: string = '[Items] Load Mixed Case Success';

export class LoadMixedCaseItems implements Action {
  readonly type: string = LOAD_MIXED_CASE_ITEMS;
  constructor(public payload: { filter: ItemFilter }) { }
}

export class LoadMixedCaseItemsSuccess implements Action {
  readonly type: string = LOAD_MIXED_CASE_ITEMS_SUCCESS;
  constructor(public payload: { items: Item[], filter?: ItemFilter }) { }
}


export const LOAD_MIXED_ITEM: string = '[Items] Load Item Mixed Item';
export const LOAD_MIXED_ITEM_SUCCESS: string = '[Items] Load Mixed Item Success';


export const LOAD_MIXED_CASE: string = '[Items] Load Mixed Case';
export const LOAD_MIXED_CASE_SUCCESS: string = '[Items] Load Mixed Case Success';
export const CLEAR_MIXED_ITEM: string = '[Items] Clear Mixed Item';

export const DELETE_MIXED_CASE_SUPPLIER: string = '[Supplier] Delete Mixed Case Supplier';
export const DELETE_MIXED_CASE_SUPPLIER_SUCCESS: string = '[Supplier] Delete Mixed Case Supplier Success';
export const DELETE_MIXED_CASE_SUPPLIER_FAIL: string = '[Supplier] Delete Mixed Case Supplier Fail';

export const DUPLICATE_ITEM_MIXED_ITEM: string = '[Items] Duplicate Item Mixed Item';

export const DUPLICATE_MIXED_ITEM: string = '[Items] Duplicate Mixed Item';
export const DUPLICATE_MIXED_ITEM_SUCCESS: string = '[Items] Duplicate Mixed Item Success';
export const DUPLICATE_MIXED_ITEM_FAIL: string = '[Items] Duplicate Mixed Item Fail';

export class DuplicateItemMixedItem implements Action {
  readonly type: string = DUPLICATE_ITEM_MIXED_ITEM;
  constructor(public payload: { clone_item_id: string, item_id: string }) { }
}

export class DuplicateMixedItem implements Action {
  readonly type: string = DUPLICATE_MIXED_ITEM;
  constructor(public payload: { clone_item_id: string, item_id: string }) { }
}

export class DuplicateMixedItemSuccess implements Action {
  readonly type: string = DUPLICATE_MIXED_ITEM_SUCCESS;
  constructor(public payload: { item: Item }) { }
}

export class DuplicateMixedItemFail implements Action {
  readonly type: string = DUPLICATE_MIXED_ITEM_FAIL;
  constructor(public payload: { error: any }) { }
}

export class DeleteMixedCaseSupplier implements Action {
  readonly type: string = DELETE_MIXED_CASE_SUPPLIER;
  constructor(public payload: { _id: string, item_id: string }) { }
}

export class DeleteMixedItem implements Action {
  readonly type: string = DELETE_MIXED_ITEM;
  constructor(public payload: { _id: string, item_id: string }) { }
}

export class ClearMixedItem implements Action {
  readonly type: string = CLEAR_MIXED_ITEM;
  constructor() { }
}

export class LoadMixedItemSuccess implements Action {
  readonly type: string = LOAD_MIXED_ITEM_SUCCESS;
  constructor(public payload: { item: Item }) { }
}

export class LoadMixedCase implements Action {
  readonly type: string = LOAD_MIXED_CASE;
  constructor(public payload: { id: string }) { }
}

export class LoadMixedCaseSuccess implements Action {
  readonly type: string = LOAD_MIXED_CASE_SUCCESS;
  constructor(public payload: { item: Item }) { }
}

export class LoadMixedItem implements Action {
  readonly type: string = LOAD_MIXED_ITEM;
  constructor(public payload: { id: string, item_id: string }) { }
}

export const CREATE_MIXED_ITEM: string = '[Items] Create Mixed Item';
export const CREATE_MIXED_ITEM_SUCCESS: string = '[Items] Create Mixed Item Success';

export class CreateMixedItem implements Action {
  readonly type: string = CREATE_MIXED_ITEM;
  constructor(public payload: { item: ItemInput }) { }
}

export class CreateMixedItemSuccess implements Action {
  readonly type: string = CREATE_MIXED_ITEM_SUCCESS;
  constructor(public payload: { item: Item }) { }
}

export const UPDATE_MIXED_ITEM: string = '[Items] Update Mixed Item';

export const UPDATE_MIXED_ITEM_SUCCESS: string = '[Items] Update Mixed Item Success';
export class UpdateMixedItem implements Action {
  readonly type: string = UPDATE_MIXED_ITEM;
  constructor(public payload: { item: ItemMixedCaseItemInput }) { }
}

export class UpdateMixedItemSuccess implements Action {
  readonly type: string = UPDATE_MIXED_ITEM_SUCCESS;
  constructor(public payload: { item: Item }) { }
}


///

export const LOAD_ITEM: string = '[Items] Load Item';
export const LOAD_ITEM_SUCCESS: string = '[Items] Load Item Success';
export const LOAD_ITEM_FAIL: string = '[Items] Load Item Fail';
export const CLEAR_ITEM: string = '[Items] Clear Item';

export const DUPLICATE_ITEM: string = '[Items] Duplicate Item';
export const DUPLICATE_ITEM_SUCCESS: string = '[Items] Duplicate Item Success';
export const DUPLICATE_ITEM_FAIL: string = '[Items] Duplicate Item Fail';

export const DELETE_SUPPLIER: string = '[Supplier] Delete Supplier';
export const DELETE_SUPPLIER_SUCCESS: string = '[Supplier] Delete Supplier Success';
export const DELETE_SUPPLIER_FAIL: string = '[Supplier] Delete Supplier Fail';

export class DuplicateItem implements Action {
  readonly type: string = DUPLICATE_ITEM;
  constructor(public payload: { _id: string }) { }
}

export class DuplicateItemSuccess implements Action {
  readonly type: string = DUPLICATE_ITEM_SUCCESS;
  constructor(public payload: { item: Item }) { }
}
export class DuplicateItemFail implements Action {
  readonly type: string = DUPLICATE_ITEM_FAIL;
  constructor(public payload: { error: any }) { }
}
export class DeleteSupplier implements Action {
  readonly type: string = DELETE_SUPPLIER;
  constructor(public payload: { _id: string, item_id: string }) { }
}
export class DeleteSupplierSuccess implements Action {
  readonly type: string = DELETE_SUPPLIER_SUCCESS;
  constructor(public payload: { supplierItem: SupplierItem }) { }
}

export class DeleteSupplierFail implements Action {
  readonly type: string = DELETE_SUPPLIER_FAIL;
  constructor(public payload: { error: any }) { }
}

export class ClearItem implements Action {
  readonly type: string = CLEAR_ITEM;
  constructor() { }
}

export class LoadItem implements Action {
  readonly type: string = LOAD_ITEM;
  constructor(public payload: { id: string }) { }
}
export class LoadItemSuccess implements Action {
  readonly type: string = LOAD_ITEM_SUCCESS;
  constructor(public payload: { item: Item }) { }
}

export class LoadItemFail implements Action {
  readonly type: string = LOAD_ITEM_FAIL;
  constructor(public payload: { error: any }) { }
}

export const LOAD_SUGGESTED_IMAGES: string = '[Items] Load Suggested Images';
export const LOAD_SUGGESTED_IMAGES_SUCCESS: string = '[Items] Load Suggested Images Success';
export const LOAD_SUGGESTED_IMAGES_FAIL: string = '[Items] Load Suggested Images Fail';

export class LoadSuggestedImages implements Action {
  readonly type: string = LOAD_SUGGESTED_IMAGES;
  constructor(public payload: { brandId: string }) { }
}

export class LoadSuggestedImagesSuccess implements Action {
  readonly type: string = LOAD_SUGGESTED_IMAGES_SUCCESS;
  constructor(public payload: { itemImages: ItemImage[] }) { }
}

export class LoadSuggestedImagesFail implements Action {
  readonly type: string = LOAD_SUGGESTED_IMAGES_FAIL;
  constructor(public payload: { error: any }) { }
}

export const CREATE_ITEM: string = '[Items] Create Item';
export const CREATE_ITEM_SUCCESS: string = '[Items] Create Item Success';
export const CREATE_ITEM_FAIL: string = '[Items] Create Item Fail';



export class CreateItem implements Action {
  readonly type: string = CREATE_ITEM;
  constructor(public payload: { item: ItemInput }) { }
}
export class CreateItemSuccess implements Action {
  readonly type: string = CREATE_ITEM_SUCCESS;
  constructor(public payload: { item: Item }) { }
}

export class CreateItemFail implements Action {
  readonly type: string = CREATE_ITEM_FAIL;
  constructor(public payload: { error: any }) { }
}

export const UPDATE_ITEM: string = '[Items] Update Item';
export const UPDATE_ITEM_SUCCESS: string = '[Items] Update Item Success';
export const UPDATE_ITEM_FAIL: string = '[Items] Update Item Fail';
export class UpdateItem implements Action {
  readonly type: string = UPDATE_ITEM;
  constructor(public payload: { item: ItemInput }) { }
}
export class UpdateItemSuccess implements Action {
  readonly type: string = UPDATE_ITEM_SUCCESS;
  constructor(public payload: { item: Item }) { }
}
export class UpdateItemFail implements Action {
  readonly type: string = UPDATE_ITEM_FAIL;
  constructor(public payload: { error: any }) { }
}

export const DELETE_MIXED_ITEM: string = '[Items] Delete Mixed Item';

export const DELETE_MIXED_ITEM_SUCCESS: string = '[Items] Delete Mixed Item Success';

export const DELETE_ITEM: string = '[Items] Delete Item';
export const DELETE_ITEM_SUCCESS: string = '[Items] Delete Item Success';
export const DELETE_ITEM_FAIL: string = '[Items] Delete Item Fail';

export const CLEAR_SHOW_DELETE_ERROR: string = '[Items] Clear Delete Error';
export class ClearDeleteError implements Action {
  readonly type: string = CLEAR_SHOW_DELETE_ERROR;
  constructor(public payload: { showDeleteError: string, showDeleteConfirmation: boolean }) { }
}

export class DeleteItem implements Action {
  readonly type: string = DELETE_ITEM;
  constructor(public payload: { _id: string }) { }
}

export class DeleteItemSuccess implements Action {
  readonly type: string = DELETE_ITEM_SUCCESS;
  constructor(public payload: { item: Item }) { }
}

export class DeleteMixedItemSuccess implements Action {
  readonly type: string = DELETE_MIXED_ITEM_SUCCESS;
  constructor(public payload: { item: Item }) { }
}

export class DeleteItemFail implements Action {
  readonly type: string = DELETE_ITEM_FAIL;
  constructor(public payload: { error: any }) { }
}

export const DELETE_ITEM_MIXED_CASE_IMAGE = '[Items] Delete Item Mixed Case Image';
export const DELETE_ITEM_MIXED_CASE_IMAGE_SUCCESS = '[Items] Delete Item Mixed Case Image Success';

export const DELETE_ITEM_MIXED_IMAGE = '[Items] Delete Item Mixed Image';
export const DELETE_ITEM_MIXED_IMAGE_SUCCESS = '[Items] Delete Item Mixed Image Success';

export const DELETE_ITEM_IMAGE = '[Items] Delete Item Image';
export const DELETE_ITEM_IMAGE_SUCCESS = '[Items] Delete Item Image Success';
export const DELETE_ITEM_IMAGE_FAIL = '[Items] Delete Item Image Fail';

export class DeleteItemMixedImage implements Action {
  readonly type: string = DELETE_ITEM_MIXED_IMAGE;
  constructor(public payload: { itemMixedCaseItem_id: string, _id: string }) { }
}

export class DeleteItemMixedImageSuccess implements Action {
  readonly type: string = DELETE_ITEM_MIXED_IMAGE_SUCCESS;
  constructor(public payload: { itemMixedCaseItem_id: string, _id: string }) { }
}

export class DeleteItemMixedCaseImage implements Action {
  readonly type: string = DELETE_ITEM_MIXED_CASE_IMAGE;
  constructor(public payload: { item_id: string, _id: string }) { }
}

export class DeleteItemMixedCaseImageSuccess implements Action {
  readonly type: string = DELETE_ITEM_MIXED_CASE_IMAGE_SUCCESS;
  constructor(public payload: { item_id: string, _id: string }) { }
}

export class DeleteItemImage implements Action {
  readonly type: string = DELETE_ITEM_IMAGE;
  constructor(public payload: { item_id: string, _id: string }) { }
}

export class DeleteItemImageSuccess implements Action {
  readonly type: string = DELETE_ITEM_IMAGE_SUCCESS;
  constructor(public payload: { item_id: string, _id: string }) { }
}
export class DeleteItemImageFail implements Action {
  readonly type: string = DELETE_ITEM_IMAGE_FAIL;
  constructor(public payload: { error: any }) { }
}

export const UPLOAD_ITEM_MIXED_IMAGE = '[Items] Upload Item Mixed Image';
export const UPLOAD_ITEM_MIXED_IMAGE_SUCCESS = '[Items] Upload Item Mixed Image Success';

export const UPLOAD_ITEM_IMAGE = '[Items] Upload Item Image';
export const UPLOAD_ITEM_IMAGE_LISTING = '[Items] Upload Item Image Listing';

export const UPLOAD_ITEM_IMAGE_SUCCESS = '[Items] Upload Item Image Success';
export const UPLOAD_ITEM_IMAGE_FAIL = '[Items] Upload Item Image Fail';

export class UploadItemImage implements Action {
  readonly type: string = UPLOAD_ITEM_IMAGE;
  constructor(public payload: ItemImage) { }
}

export class UploadItemMixedItemImage implements Action {
  readonly type: string = UPLOAD_ITEM_MIXED_IMAGE;
  constructor(public payload: ItemImage) { }
}

export class UploadItemMixedItemImageSuccess implements Action {
  readonly type: string = UPLOAD_ITEM_MIXED_IMAGE_SUCCESS;
  constructor(public payload: ItemImage) { }
}

export class UploadItemImageListing implements Action {
  readonly type: string = UPLOAD_ITEM_IMAGE_LISTING;
  constructor(public payload: { item_id: string, file, listingBanner: boolean }) { }
}

export class UploadItemImageSuccess implements Action {
  readonly type: string = UPLOAD_ITEM_IMAGE_SUCCESS;
  constructor(public payload: ItemImage) { }
}
export class UploadItemImageFail implements Action {
  readonly type: string = UPLOAD_ITEM_IMAGE_FAIL;
  constructor(public payload: { error: any }) { }
}

export const UPLOAD_DB_ITEM_IMAGE = '[Items] Upload Item Image';
export const UPLOAD_DB_ITEM_IMAGE_SUCCESS = '[Items] Upload Item Image Success';
export const UPLOAD_DB_ITEM_IMAGE_FAIL = '[Items] Upload Item Image Fail';

export class UploadDBItemImage implements Action {
  readonly type: string = UPLOAD_DB_ITEM_IMAGE;
  constructor(public payload: { itemImage: ItemImage, destItemId: string }) { }
}
export class UploadDBItemImageSuccess implements Action {
  readonly type: string = UPLOAD_DB_ITEM_IMAGE_SUCCESS;
}
export class UploadDBItemImageFail implements Action {
  readonly type: string = UPLOAD_DB_ITEM_IMAGE_FAIL;
  constructor(public payload: { error: any }) { }
}

export const CREATE_MIXED_ITEM_DETAIL: string = '[Items] Create Mixed ItemDetail';
export const CREATE_MIXED_ITEM_DETAIL_SUCCESS: string = '[Items] Create Mixed ItemDetail Success';

export const CREATE_ITEM_DETAIL: string = '[Items] Create ItemDetail';
export const CREATE_ITEM_DETAIL_SUCCESS: string = '[Items] Create ItemDetail Success';
export const CREATE_ITEM_DETAIL_FAIL: string = '[Items] Create ItemDetail Fail';

export class CreateItemDetail implements Action {
  readonly type: string = CREATE_ITEM_DETAIL;
  constructor(public payload: { itemDetail: ItemDetailInput }) { }
}

export class CreateMixedItemDetail implements Action {
  readonly type: string = CREATE_MIXED_ITEM_DETAIL;
  constructor(public payload: { itemDetail: ItemDetailInput }) { }
}

export class CreateMixedItemDetailSuccess implements Action {
  readonly type: string = CREATE_MIXED_ITEM_DETAIL_SUCCESS;
  constructor(public payload: { itemDetail: ItemDetail }) { }
}

export class CreateItemDetailSuccess implements Action {
  readonly type: string = CREATE_ITEM_DETAIL_SUCCESS;
  constructor(public payload: { itemDetail: ItemDetail }) { }
}

export class CreateItemDetailFail implements Action {
  readonly type: string = CREATE_ITEM_DETAIL_FAIL;
  constructor(public payload: { error: any }) { }
}

export const UPDATE_MIXED_ITEM_DETAIL: string = '[Items] Update Mixed ItemDetail';
export const UPDATE_MIXED_ITEM_DETAIL_SUCCESS: string = '[Items] Update Mixed ItemDetail Success';



export const UPDATE_ITEM_DETAIL: string = '[Items] Update ItemDetail';
export const UPDATE_ITEM_DETAIL_SUCCESS: string = '[Items] Update ItemDetail Success';
export const UPDATE_ITEM_DETAIL_FAIL: string = '[Items] Update ItemDetail Fail';
export const DELETE_MIXED_ITEM_DETAIL: string = '[Items] Delete Mixed Item ItemDetail';




export class UpdateItemDetail implements Action {
  readonly type: string = UPDATE_ITEM_DETAIL;
  constructor(public payload: { itemDetail: ItemDetailInput }) { }
}

export class UpdateMixedItemDetail implements Action {
  readonly type: string = UPDATE_MIXED_ITEM_DETAIL;
  constructor(public payload: { itemDetail: ItemDetailInput }) { }
}

export class UpdateMixedItemDetailSuccess implements Action {
  readonly type: string = UPDATE_MIXED_ITEM_DETAIL_SUCCESS;
  constructor(public payload: { itemDetail: ItemDetail }) { }
}

export class UpdateItemDetailSuccess implements Action {
  readonly type: string = UPDATE_ITEM_DETAIL_SUCCESS;
  constructor(public payload: { itemDetail: ItemDetail }) { }
}

export class UpdateItemDetailFail implements Action {
  readonly type: string = UPDATE_ITEM_DETAIL_FAIL;
  constructor(public payload: { error: any }) { }
}

export class DeleteMixedItemDetail implements Action {
  readonly type: string = DELETE_MIXED_ITEM_DETAIL;
  constructor(public payload: { itemDetail: ItemDetailInput }) { }
}

export const DELETE_ITEM_DETAIL: string = '[Items] Delete ItemDetail';
export const DELETE_ITEM_DETAIL_SUCCESS: string = '[Items] Delete ItemDetail Success';
export const DELETE_ITEM_DETAIL_FAIL: string = '[Items] Delete ItemDetail Fail';

export class DeleteItemDetail implements Action {
  readonly type: string = DELETE_ITEM_DETAIL;
  constructor(public payload: { itemDetail: ItemDetailInput }) { }
}

export class DeleteItemDetailSuccess implements Action {
  readonly type: string = DELETE_ITEM_DETAIL_SUCCESS;
  constructor(public payload: { itemDetail: ItemDetail }) { }
}

export class DeleteItemDetailFail implements Action {
  readonly type: string = DELETE_ITEM_DETAIL_FAIL;
  constructor(public payload: { error: any }) { }
}

export const LOAD_BRANDS: string = '[Items] Load Brands';
export const LOAD_BRANDS_SUCCESS: string = '[Items] Load Brands Success';
export const LOAD_BRANDS_FAIL: string = '[Items] Load Brands Fail';

export class LoadBrands implements Action {
  readonly type: string = LOAD_BRANDS;
  constructor() { }
}

export class LoadBrandsSuccess implements Action {
  readonly type: string = LOAD_BRANDS_SUCCESS;
  constructor(public payload: { brands: ItemBrand[] }) { }
}

export class LoadBrandsFail implements Action {
  readonly type: string = LOAD_BRANDS_FAIL;
  constructor(public payload: { error: any }) { }
}

export const LOAD_COUNTRIES: string = '[Items] Load Countries';
export const LOAD_COUNTRIES_SUCCESS: string = '[Items] Load Countries Success';
export const LOAD_COUNTRIES_FAIL: string = '[Items] Load Countries Fail';

export class LoadCountries implements Action {
  readonly type: string = LOAD_COUNTRIES;
  constructor() { }
}

export class LoadCountriesSuccess implements Action {
  readonly type: string = LOAD_COUNTRIES_SUCCESS;
  constructor(public payload: { countries: Country[] }) { }
}

export class LoadCountriesFail implements Action {
  readonly type: string = LOAD_COUNTRIES_FAIL;
  constructor(public payload: { error: any }) { }
}

export const LOAD_SUPPLIERS: string = '[Items] Load Suppliers';
export const LOAD_SUPPLIERS_SUCCESS: string = '[Items] Load Suppliers Success';
export const LOAD_SUPPLIERS_FAIL: string = '[Items] Load Suppliers Fail';

export class LoadSuppliers implements Action {
  readonly type: string = LOAD_SUPPLIERS;
  constructor() { }
}

export class LoadSuppliersSuccess implements Action {
  readonly type: string = LOAD_SUPPLIERS_SUCCESS;
  constructor(public payload: { suppliers: Supplier[] }) { }
}

export class LoadSuppliersFail implements Action {
  readonly type: string = LOAD_SUPPLIERS_FAIL;
  constructor(public payload: { error: any }) { }
}

export const LOAD_ITEM_DETAIL_TYPES: string = '[Items] Load Item Types Detail';
export const LOAD_ITEM_DETAIL_TYPES_SUCCESS: string = '[Items] Load Item Detail Types Success';
export const LOAD_ITEM_DETAIL_TYPES_FAIL: string = '[Items] Load Item Detail Types Fail';

export class LoadItemDetailTypes implements Action {
  readonly type: string = LOAD_ITEM_DETAIL_TYPES;
  constructor() { }
}

export class LoadItemDetailTypesSuccess implements Action {
  readonly type: string = LOAD_ITEM_DETAIL_TYPES_SUCCESS;
  constructor(public payload: { itemDetailTypes: ItemDetailType[] }) { }
}

export class LoadItemDetailTypesFail implements Action {
  readonly type: string = LOAD_ITEM_DETAIL_TYPES_FAIL;
  constructor(public payload: { error: any }) { }
}

export const LOAD_MIXED_CASE_SUPPLIER_ITEM: string = '[Items] Load Mixed Case Supplier Item';
export const LOAD_MIXED_CASE_SUPPLIER_ITEM_SUCCESS: string = '[Items] Load Mixed Case Supplier Item Success';
export const LOAD_MIXED_CASE_SUPPLIER_ITEM_FAIL: string = '[Items] Load Mixed Case Supplier Item Fail';


export const LOAD_SUPPLIER_ITEM: string = '[Items] Load Supplier Item';
export const LOAD_SUPPLIER_ITEM_SUCCESS: string = '[Items] Load Supplier Item Success';
export const LOAD_SUPPLIER_ITEM_FAIL: string = '[Items] Load Supplier Item Fail';


export class LoadMixedCaseSupplierItem implements Action {
  readonly type: string = LOAD_MIXED_CASE_SUPPLIER_ITEM;
  constructor(public payload: { itemID: string, supplierID: string, mixed_case: boolean }) { }
}
export class LoadSupplierItem implements Action {
  readonly type: string = LOAD_SUPPLIER_ITEM;
  constructor(public payload: { itemID: string, supplierID: string, mixed_case: boolean }) { }
}

export class LoadSupplierItemSuccess implements Action {
  readonly type: string = LOAD_SUPPLIER_ITEM_SUCCESS;
  constructor(public payload: { supplierItem: SupplierItem }) { }
}


export class LoadMixedCaseSupplierItemSuccess implements Action {
  readonly type: string = LOAD_MIXED_CASE_SUPPLIER_ITEM_SUCCESS;
  constructor(public payload: { supplierItem: SupplierItem }) { }
}

export class LoadSupplierItemFail implements Action {
  readonly type: string = LOAD_SUPPLIER_ITEM_FAIL;
  constructor(public payload: { error: any }) { }
}

export const CREATE_SUPPLIER_ITEM: string = '[Items] Create Supplier Item';
export const CREATE_SUPPLIER_ITEM_SUCCESS: string = '[Items] Create Supplier Item Success';
export const CREATE_SUPPLIER_ITEM_FAIL: string = '[Items] Create Supplier Item Fail';

export class CreateSupplierItem implements Action {
  readonly type: string = CREATE_SUPPLIER_ITEM;
  constructor(public payload: { supplierItemInput: SupplierItemInput }) { }
}

export class CreateSupplierItemSuccess implements Action {
  readonly type: string = CREATE_SUPPLIER_ITEM_SUCCESS;
  constructor(public payload: { supplierItem: SupplierItem }) { }
}

export class CreateSupplierItemFail implements Action {
  readonly type: string = CREATE_SUPPLIER_ITEM_FAIL;
  constructor(public payload: { error: any }) { }
}

export const UPDATE_SUPPLIER_ITEM: string = '[Items] Update Supplier Item';
export const UPDATE_SUPPLIER_ITEM_SUCCESS: string = '[Items] Update Supplier Item Success';
export const UPDATE_SUPPLIER_ITEM_FAIL: string = '[Items] Update Supplier Item Fail';


export const UPDATE_ITEM_INLINE: string = '[Items] Update Item Inline';
export const UPDATE_ITEM_INLINE_SUCCESS: string = '[Items] Update Item Inline Success';
export const UPDATE_ITEM_INLINE_FAIL: string = '[Items] Update Item Inline Fail';

export class UpdateItemInline implements Action {
  readonly type: string = UPDATE_ITEM_INLINE;
  constructor(public payload: { item: ItemInline }) { }
}

export class UpdateItemInlineFail implements Action {
  readonly type: string = UPDATE_ITEM_INLINE_FAIL;
  constructor(public payload: { error: any }) { }
}

export class UpdateItemInlineSuccess implements Action {
  readonly type: string = UPDATE_ITEM_INLINE_SUCCESS;
  constructor(public payload: { item: Item, supplierItemInput: SupplierItemInput }) { }
}



export class UpdateSupplierItem implements Action {
  readonly type: string = UPDATE_SUPPLIER_ITEM;
  constructor(public payload: { supplierItemInput: SupplierItemInput }) { }
}

export class UpdateSupplierItemSuccess implements Action {
  readonly type: string = UPDATE_SUPPLIER_ITEM_SUCCESS;
  constructor(public payload: { supplierItem: SupplierItem }) { }
}

export class UpdateSupplierItemFail implements Action {
  readonly type: string = UPDATE_SUPPLIER_ITEM_FAIL;
  constructor(public payload: { error: any }) { }
}

export const LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST: string = '[Items] Load Supplier Item Condition Suggest';
export const LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST_SUCCESS: string = '[Items] Load Supplier Item Condition Suggest Success';
export const LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST_FAIL: string = '[Items] Load Supplier Item Condition Suggest Fail';

export class LoadSupplierItemConditionSuggest implements Action {
  readonly type: string = LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST;
  constructor(public payload: { condition: string }) { }
}

export class LoadSupplierItemConditionSuggestSuccess implements Action {
  readonly type: string = LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST_SUCCESS;
  constructor(public payload: { conditions: string[] }) { }
}

export class LoadSupplierItemConditionSuggestFail implements Action {
  readonly type: string = LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST_FAIL;
  constructor(public payload: { error: any }) { }
}

export const CREATE_BRAND: string = '[Items] Create Brand';
export const CREATE_BRAND_SUCCESS: string = '[Items] Create Brand Success';
export const CREATE_BRAND_FAIL: string = '[Items] Create Brand Fail';

export class CreateBrand implements Action {
  readonly type: string = CREATE_BRAND;
  constructor(public payload: { brandInput: BrandInput }) { }
}

export class CreateBrandSuccess implements Action {
  readonly type: string = CREATE_BRAND_SUCCESS;
  constructor(public payload: { brand: ItemBrand }) { }
}

export class CreateBrandFail implements Action {
  readonly type: string = CREATE_BRAND_FAIL;
  constructor(public payload: { error: any }) { }
}

export const UPDATE_BRAND: string = '[Items] Update Brand';
export const UPDATE_BRAND_SUCCESS: string = '[Items] Update Brand Success';
export const UPDATE_BRAND_FAIL: string = '[Items] Update Brand Fail';

export class UpdateBrand implements Action {
  readonly type: string = UPDATE_BRAND;
  constructor(public payload: { id: string, brandInput: BrandInput }) { }
}

export class UpdateBrandSuccess implements Action {
  readonly type: string = UPDATE_BRAND_SUCCESS;
  constructor(public payload: { brand: ItemBrand }) { }
}

export class UpdateBrandFail implements Action {
  readonly type: string = UPDATE_BRAND_FAIL;
  constructor(public payload: { error: any }) { }
}

export const GET_EXCEL_TEMPLATE: string = '[Items] Get Excel Template';
export const GET_EXCEL_TEMPLATE_SUCCESS: string = '[Items] Get Excel Template Success';
export const GET_EXCEL_TEMPLATE_FAIL: string = '[Items] Get Excel Template Fail';

export class GetExcelTemplate implements Action {
  readonly type: string = GET_EXCEL_TEMPLATE;
  constructor() { }
}

export class GetExcelTemplateSuccess implements Action {
  readonly type: string = GET_EXCEL_TEMPLATE_SUCCESS;
  constructor(public payload: { excelTemplateUrl: string }) { }
}

export class GetExcelTemplateFail implements Action {
  readonly type: string = GET_EXCEL_TEMPLATE_FAIL;
  constructor(public payload: { error: any }) { }
}

export const IMPORT_ITEMS: string = '[Items] Import Items';
export const IMPORT_ITEMS_SUCCESS: string = '[Items] Import Items Success';
export const IMPORT_ITEMS_FAIL: string = '[Items] Import Items Fail';

export class ImportItems implements Action {
  readonly type: string = IMPORT_ITEMS;
  constructor(public payload: { itemsFile: File }) { }
}

export class ImportItemsSuccess implements Action {
  readonly type: string = IMPORT_ITEMS_SUCCESS;
  constructor(public payload: { status: string }) { }
}

export class ImportItemsFail implements Action {
  readonly type: string = IMPORT_ITEMS_FAIL;
  constructor(public payload: { error: any }) { }
}

export const EXPORT_ITEMS: string = '[Items] Export Items';
export const EXPORT_ITEMS_SUCCESS: string = '[Items] Export Items Success';
export const EXPORT_ITEMS_FAIL: string = '[Items] Export Items Fail';

export class ExportItems implements Action {
  readonly type: string = EXPORT_ITEMS;
  constructor(public payload: { filetype: string }) { }
}

export class ExportItemsSuccess implements Action {
  readonly type: string = EXPORT_ITEMS_SUCCESS;
  constructor() { }
}

export class ExportItemsFail implements Action {
  readonly type: string = EXPORT_ITEMS_FAIL;
  constructor(public payload: { error: any }) { }
}

export const CREATE_ITEM_REVIEW: string = '[Items] Create Item Review';
export const CREATE_ITEM_REVIEW_SUCCESS: string = '[Items] Create Item Review Success';
export const CREATE_ITEM_REVIEW_FAIL: string = '[Items] Create Item Review Fail';

export class CreateItemReview implements Action {
  readonly type: string = CREATE_ITEM_REVIEW;
  constructor(public payload: { item_id: string, text: string }) { }
}

export class CreateItemReviewSuccess implements Action {
  readonly type: string = CREATE_ITEM_REVIEW_SUCCESS;
  constructor(public payload: { itemReview: ItemReview }) { }
}

export class CreateItemReviewFail implements Action {
  readonly type: string = CREATE_ITEM_REVIEW_FAIL;
  constructor(public payload: { error: any }) { }
}


export type ItemsAction = LoadItems
  | LoadItemsFail
  | LoadItemsSuccess
  | SearchItems
  | SearchItemsFail
  | SearchItemsSuccess
  | LoadHighLevelTypes
  | LoadHighLevelTypesSuccess
  | LoadHighLevelTypesFail
  | LoadItem
  | LoadItemFail
  | LoadItemSuccess
  | CreateItem
  | CreateItemFail
  | CreateItemSuccess
  | UpdateItem
  | UpdateItemFail
  | UpdateItemSuccess
  | DeleteItem
  | DeleteItemFail
  | DeleteItemSuccess
  | DeleteItemImage
  | DeleteItemImageFail
  | DeleteItemImageSuccess
  | CreateItemDetail
  | CreateItemDetailFail
  | CreateItemDetailSuccess
  | UpdateItemDetail
  | UpdateItemDetailFail
  | UpdateItemDetailSuccess
  | DeleteItemDetail
  | DeleteItemDetailFail
  | DeleteItemDetailSuccess
  | LoadBrands
  | LoadBrandsFail
  | LoadBrandsSuccess
  | LoadCountries
  | LoadCountriesFail
  | LoadCountriesSuccess
  | LoadItemDetailTypes
  | LoadItemDetailTypesFail
  | LoadItemDetailTypesSuccess
  | LoadSuppliers
  | LoadSuppliersFail
  | LoadSuppliersSuccess
  | LoadSupplierItem
  | LoadSupplierItemFail
  | LoadSupplierItemSuccess
  | CreateSupplierItem
  | CreateSupplierItemFail
  | CreateSupplierItemSuccess
  | UpdateSupplierItem
  | UpdateSupplierItemFail
  | UpdateSupplierItemSuccess
  | LoadSupplierItemConditionSuggest
  | LoadSupplierItemConditionSuggestFail
  | LoadSupplierItemConditionSuggestSuccess
DuplicateItem
DuplicateItemFail
DuplicateItemSuccess
DeleteSupplier
DeleteSupplierFail
DeleteSupplierSuccess
UpdateItemInlineSuccess
UpdateItemInlineFail
UpdateItemInline
LoadLabels
LoadLabelsSuccess
LoadSuggestedImagesFail;

